<template>
  <div>
    <div class="version-header">
      <div class="fl">
        <img class="logo" src="../../assets/logo.png" />
      </div>
      <el-menu class="fr" mode="horizontal" @select="handleSelect" background-color="#545c64"
        text-color="#fff" active-text-color="#ffd04b">
        <el-submenu index="server" popper-class="popper-class">
          <template slot="title">服务下载</template>
          <el-menu-item index="0">
            <a href="https://lqckxt.cn/resources/network/testing.jar" target="_blank">测温软件</a>
          </el-menu-item>
          <el-menu-item index="1">
            <a href="https://lqckxt.cn/resources/测温上传.rar" target="_blank">温度上传</a>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="tool" popper-class="popper-class">
          <template slot="title">工具下载</template>
          <el-menu-item index="2">
            <a href="https://lqckxt.cn/resources/x64.7z" target="_blank">x64/x32</a>
          </el-menu-item>
          <el-menu-item index="3">
            <a href="https://lqckxt.cn/resources/xp.7z" target="_blank">xp</a>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="resources" popper-class="popper-class">
          <template slot="title">资源下载</template>
          <el-menu-item index="4">
            <a href="https://lqckxt.cn/resources/util.7z" target="_blank">普通资源</a>
          </el-menu-item>
          <el-menu-item index="5">
            <a href="https://lqckxt.cn/resources/util2020-01-15.rar" target="_blank">快捷资源</a>
          </el-menu-item>
        </el-submenu>
        <el-menu-item><a href="/" target="_blank">后台管理</a></el-menu-item>
      </el-menu>
    </div>
    <div class="version-list">
      <el-timeline>
        <el-timeline-item v-for="(item,index) in list" :type="index===0?'primary':''" :key="index" :timestamp="item.versionAlias" placement="top">
          <el-card>
            <el-tag>{{item.updateTime}}</el-tag>
            <p v-for="(e,i) in item.introduce" :key="i">{{e}}</p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        list:[]
      }
    },
    mounted() {
      this.getVersion()
    },
    methods: {
      handleSelect(key, keyPath) {
        console.log(key, keyPath);
      },
      getVersion() {
        this.$axios.Get(this.$api.versionList).then(res => {
          this.list=res.data.network
        })
      }
    }
  }
</script>


<style lang="less">
.version-header{
  height: 60px;
  background: #545c64;padding: 0 40px;position: sticky;top: 0;    z-index: 1;

  .logo{
    width: 50px;margin-top: 5px;
  }
}
  .version-list{
    padding: 20px 20px 20px 0px;
    .el-card__body{
          padding: 10px;
          font-size: 12px;
          line-height: 1.8;
    }
  }



  .popper-class .el-menu--popup {
    min-width: auto;

    a {
      color: #fff;
    }
  }

  .popper-class .el-menu--popup .el-menu--horizontal .el-menu .el-menu-item,
  .popper-class .el-menu--popup .el-menu--horizontal .el-menu .el-submenu__title {
    padding: 0px 30px;

  }
</style>
